<template>
  <div class="content">
    <div class="d-flex">
      <h4>Feed <span v-if="FeedTitle"> - {{ FeedTitle }} ({{ FeedNodeCount | format }})</span></h4>
      <b-button
        v-show="feedMeta.export_instructions"
        size="sm"
        variant="primary"
        title="Export to spreadsheet"
        class="btn-primary ml-2 text-nowrap"
        :disabled="exporting"
        @click="exportFeed"
      >
        <span v-if="exporting">Exporting..</span>
        <span v-else>Export</span>
      </b-button>
    </div>

    <!-- Search bar -->
     <b-input-group class="mt-3">
      <template #append>
        <b-button size="sm" variant="white" title="Clear text" @click="clearSearchText">
          Clear filter
        </b-button>
      </template>
      <b-form-input
        v-model="searchText"
        placeholder="Filter items"
        autocomplete="off"
        trim
        debounce="500"
        autofocus
        @update="filterItems"
      ></b-form-input>
    </b-input-group>
    <!-- Feed -->
    <div class="mt-4">
      <h5 v-if="loadingCount > 0">Fetching feed..</h5>
      <h5 v-else-if="feedData === null">Feed unavailable.</h5>
      <div v-else-if="feedData.length === 0">
        <feed-no-results :searchText="searchText" />
      </div>
      <div v-else>
        <div v-for="node in feedData" :key="node.id">
          <b-card class="mt-2" :title="`# ${node.id}`">
            <b-card-text>
              <pre v-highlightjs="node.node_xml"><code class="language-xml"></code></pre>
            </b-card-text>
          </b-card>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <b-pagination
      v-model="feedPage"
      :disabled="loadingCount > 0"
      :total-rows="feedRows"
      :per-page="feedPerPage"
      aria-controls="my-table"
      first-number
      last-number
      prev-text="Previous"
      next-text="Next"
      class="mt-2"
      @change="scheduleFetchData"
    ></b-pagination>
  </div>
</template>

<script>
const FeedNoResults = () => import('@/components/feed/NoResults.vue');

export default {
  name: 'Feed',
  components: {
    FeedNoResults,
  },
  computed: {
    FeedID() {
      return this.$route.params.id;
    },
    FeedTitle() {
      return Object.prototype.hasOwnProperty.call(this.feedMeta, 'title') ? this.feedMeta.title : '';
    },
    FeedNodeCount() {
      return Object.prototype.hasOwnProperty.call(this.feedMeta, 'node_count') ? this.feedMeta.node_count : 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      exporting: false,
      searchText: '',
      feedMeta: {},
      feedData: [],
      feedRows: 0,
      feedPage: 1,
      feedPerPage: 5,
    };
  },
  methods: {
    fetchMeta() {
      this.loadingCount += 1;
      this.$http
        .get(`/feed/${this.FeedID}/meta`)
        .then((res) => {
          this.feedMeta = res.body.feedMeta;
        })
        .catch((err) => {
          alert(`Failed to fetch feed meta: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    scheduleFetchData() {
      setTimeout(() => {
        this.fetchData();
      }, 0);
    },
    fetchData() {
      this.loadingCount += 1;
      this.$http
        .get(`/feed/${this.FeedID}/data`)
        .query({ searchText: this.searchText })
        .query({ page: this.feedPage })
        .query({ perPage: this.feedPerPage })
        .then((res) => {
          this.feedData = res.body.feedData;
          this.feedRows = res.body.feedRows;
        })
        .catch((err) => {
          alert(`Failed to fetch feed data: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    exportFeed() {
      if (this.exporting) return;
      this.exporting = true;
      this.$http
        .get(`/feed/${this.FeedID}/export`)
        .then((res) => {
          const link = document.createElement('a');
          link.href = res.body.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          alert('Failed to export feed');
        })
        .finally(() => {
          this.exporting = false;
        });
    },
    filterItems() {
      this.feedPage = 1;
      this.fetchData();
    },
    clearSearchText() {
      this.feedPage = 1;
      this.searchText = '';
      this.fetchData();
    },
  },
  created() {
    this.fetchMeta();
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
code {
  display: block;
  white-space: pre-wrap
}
</style>
